import {Skylab, AmplitudeContextProvider} from '@amplitude/skylab-js-client';
import Amplitude from 'amplitude-js';

/**
 *
 * Initializes amplitude client sdk
 */
const initializeAmplitudeSdk = () => {
  const amplitudeAnalyticsApiKey = process.env.amplitudeAnalyticsApiKey;
  const amplitudeInstance = Amplitude.getInstance();
  amplitudeInstance.init(amplitudeAnalyticsApiKey);
  const amplitudeExperimentClientId = process.env.amplitudeExperimentClientId;
  const skylabInstance = Skylab.init(amplitudeExperimentClientId);
  /* Add the amplitude identity to the SkylabUser */
  skylabInstance.setContextProvider(
      new AmplitudeContextProvider(amplitudeInstance),
  );
  skylabInstance.start();
};

/**
 *
 * Called when the Gatsby browser runtime first starts.
 */
export const onClientEntry = () => {
  initializeAmplitudeSdk();
  console.log('Amplitude client init');
};
